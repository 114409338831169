import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import ContactSlide from '../contactSlide/';

import { ReactComponent as Logo } from '../../assets/Smail-Logo.svg';

import './style.css';

const NavStyled = styled.nav`
  position: fixed;
  width: 100%;
  height: 3.5rem;
  background-color: #0e0e0e;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  z-index: 9;

  @media (min-width: 768px) {
    height: 7rem;
    padding: 0 3rem;
  }
`;

const LogoStyled = styled(Logo)`
  height: 30px;

  @media (min-width: 768px) {
    height: 42px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const BurgerWrap = styled.div`
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  &:hover {
    cursor: pointer;
  }
`;

const BurgerLine = styled.div`
  height: 2px;
  width: 2.5rem;
  background: #fff;
  transform: ${({ rotateIt, top }) =>
    rotateIt
      ? top
        ? `rotate(45deg) translate(7px,6px)`
        : `rotate(-45deg) translate(5px, -4px)`
      : `rotate(0deg)`};
  /* transform: rotate(30deg); */
  opacity: ${({ fade }) => (fade ? '0' : '1')};
  transition: all 0.5s;
  margin: 3px 0;
`;

export default function Navbar() {
  let history = useHistory();
  const [open, setOpen] = useState(false);
  return (
    <NavStyled>
      <LogoStyled
        onClick={() => {
          setOpen(false);
          history.push('/');
        }}
      />
      <BurgerWrap onClick={() => setOpen(!open)}>
        <BurgerLine rotateIt={open} fade={false} top={true} />
        <BurgerLine rotateIt={false} fade={open} top={false} />
        <BurgerLine rotateIt={open} fade={false} top={false} />
      </BurgerWrap>
      <ContactSlide open={open} setOpen={setOpen} />
    </NavStyled>
  );
}
