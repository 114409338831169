import Navbar from './components/navbar/';
import Routes from './Routes';
import Footer from './components/footer/';

function App() {
  return (
    <>
      <Navbar />
      <Routes />
      <Footer />
    </>
  );
}

export default App;
