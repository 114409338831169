import { useEffect, useState } from 'react';
import styled from 'styled-components';

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  font-family: pt-mono, monospace;
  font-weight: 400;
  font-style: normal;
  padding: 0 1rem;

  input,
  textarea {
    all: unset;
    position: relative;
    background: transparent;
    border: 1px solid lightgray;
    margin: 1rem;
    padding: 1rem;
    color: #0e0e0e;
    /* width: 100%; */
    /* max-width: 400px; */
    flex-grow: 1;
    width: auto;

    @media (min-width: 768px) {
      margin: 1rem 0;
      max-width: unset;
      width: unset;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      height: 4px;
      width: 4px;
      background: lightgray;
    }
  }

  button {
    all: unset;
    position: relative;
    border: 1px solid #1d1d1d;
    margin: 1rem;
    padding: 1rem;
    color: #1d1d1d;
    width: 240px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 2rem;
    margin-top: 1rem;
    font-family: var(--filter-font);
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      height: 8px;
      width: 8px;
      background: #1d1d1d;
    }

    &:hover {
      cursor: pointer;
      background: #0e0e0e;
      color: #fff;
    }

    @media (min-width: 768px) {
      width: 200px;
      margin: 1rem 0 0 0;
    }
  }

  @media (min-width: 768px) {
    padding: 0;
  }
`;

const Split = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 1rem;
  }
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-rows: 85px 1fr;
  flex-grow: 1;

  input {
    max-height: 53px;
  }
`;

const ConfirmMsg = styled.span`
  color: green;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const ErrorMsg = styled.span`
  color: red;
  font-size: 10px;
  padding-left: 1rem;
  @media (min-width: 768px) {
    padding-left: 0;
  }
`;

const HoneyPot = styled.input`
  display: none !important;
`;

export default function MailForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    bots: false,
  });

  const [msgStatus, setMsgStatus] = useState({
    msg: { name: '', mail: '', sent: false, field: 'both', bots: false },
  });

  const handleDataUpdate = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onBotChange = (e) => {
    setFormData({ ...formData, bots: true });
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    try {
      const resp = await fetch(
        `https://smail-backend.onrender.com/mail/send`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );
      const data = await resp.json();
      setMsgStatus(data);
      if (data.sent) {
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: '',
          bots: false,
        });
      }
      if (data.bot) {
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: '',
          bots: false,
        });
      }
      return;
    } catch (err) {
      setMsgStatus({
        msg: { name: '', mail: '', sent: false, error: err.message },
      });
    }
  };

  const sendEnter = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      handleSendMessage(e);
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    if (msgStatus.sent) {
      setTimeout(() => {
        setMsgStatus({ ...msgStatus, sent: false });
      }, 2000);
    }
  }, [msgStatus]);

  return (
    <FormStyled onSubmit={handleSendMessage}>
      <Split>
        <InputContainer>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleDataUpdate}
          />
          {!msgStatus.sent && (
            <ErrorMsg
              style={{
                color: 'red',
                fontSize: '12px',
              }}
            >
              {msgStatus.msg.name}
            </ErrorMsg>
          )}
        </InputContainer>
        <InputContainer>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleDataUpdate}
          />
          {!msgStatus.sent && (
            <ErrorMsg
              style={{
                color: 'red',
                fontSize: '12px',
              }}
            >
              {msgStatus.msg.mail}
            </ErrorMsg>
          )}
        </InputContainer>
      </Split>
      <input
        type="text"
        name="subject"
        id="subject"
        placeholder="Subject"
        value={formData.subject}
        onChange={handleDataUpdate}
      />
      <textarea
        name="message"
        id="message"
        cols="30"
        rows="10"
        placeholder="Enter your message here"
        value={formData.message}
        onChange={handleDataUpdate}
        onKeyDown={sendEnter}
      ></textarea>
      <HoneyPot
        type="text"
        name="contact"
        value={formData.bots.in1}
        onChange={onBotChange}
      ></HoneyPot>
      <input
        style={{ display: 'none' }}
        type="text"
        name="email"
        value="test@email.com"
        onChange={onBotChange}
      ></input>
      {msgStatus.sent && <ConfirmMsg>Message sent!</ConfirmMsg>}
      <button type="submit">Send Message</button>
    </FormStyled>
  );
}
