import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';

import ProjectGallery from '../components/projectGallery';

import { AdminContext } from '../context/AdminContext';

import { ReactComponent as All } from '../assets/Smail-Portfolio-Icon-All.svg';
import { ReactComponent as Left } from '../assets/nav-arrow-left.svg';
import { ReactComponent as Right } from '../assets/nav-arrow-right.svg';

import { ReactComponent as Dark } from '../assets/Dark-Icon.svg';
import { ReactComponent as Light } from '../assets/Light-Icon.svg';

const ProjectPage = styled.div`
  padding-top: 3.5rem;
  color: #fff;
  padding-bottom: 5rem;
  min-height: 100vh;
  background: ${({ dark }) => (dark ? `#0e0e0e` : `#fff`)};
  transition: 1s background;

  @media (min-width: 768px) {
    padding: 7rem 0 2rem 0;
    color: #fff;
  }
`;

const ProjectWrap = styled.div`
  height: 100%;
  @media (min-width: 768px) {
    position: relative;
  }
`;

const ProjectHead = styled.div`
  padding: 0 1rem;
  background: ${({ dark }) => (dark ? `#0e0e0e` : `#1d1d1d`)};
  color: #fff;
  text-align: center;
  position: relative;
  transition: 1s background;

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 90%;
    background: #fff;
    opacity: 0.6;
    bottom: 2.5rem;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (min-width: 768px) {
    padding: 3rem;
    text-align: center;
    padding: 0;
  }
`;

const GalleryWrap = styled.div`
  width: unset;

  @media (min-width: 768px) {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
  }
`;

const Title = styled.span`
  display: block;
  font-weight: 600;
  font-size: 4rem;
  font-family: var(--header-font);
  text-transform: uppercase;
  padding-top: 4rem;
  line-height: 4.5rem;
  margin: 0 auto;

  @media (min-width: 768px) {
    font-size: 72px;
    margin: 0 auto;
  }
`;

const Subtitle = styled.p`
  display: inline-block;
  font-family: var(--filter-font);
  text-transform: uppercase;
  font-size: 1rem;
  padding-bottom: 4rem;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Controls = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  padding-top: 3rem;
  /* width: 130px; */
  height: 38px;

  @media (min-width: 768px) {
    padding: 0;
  }

  @media (min-width: 1024px) {
    padding-top: 0;
  }
`;

const ControlBtn = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-color: ${({ dark }) => (dark ? `#1d1d1d` : `#1d1d1d`)};
  background: ${({ dark }) => (dark ? `#1d1d1d` : `#1d1d1d`)};
  color: ${({ dark }) => (dark ? `#fff` : `#0e0e0e`)};
  opacity: ${({ disabled }) => (disabled ? '.2' : '1')};
  user-select: none;

  svg {
    height: ${({ all }) => (all ? '16px' : '22px')};
    width: 22px;
  }

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 768px) {
    width: 38px;
    height: 38px;
  }
`;

const ThemeBtn = styled.div`
  display: none;
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border: 2px solid #1d1d1d;
    background: #1d1d1d;

    svg {
      height: ${({ all }) => (all ? '16px' : '20px')};
      width: 20px;
    }
  }
`;

const ButtonsWrap = styled.div`
  @media (min-width: 1024px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 3rem;
    position: sticky;
    bottom: 2rem;
  }
`;

export default function Project() {
  const history = useHistory();
  const { projects } = useContext(AdminContext);
  const [siteData, setSiteData] = useState({});
  const [prevSite, setPrevSite] = useState({});
  const [nextSite, setNextSite] = useState({});

  const [dark, setDark] = useState(false);

  let { site } = useParams();

  useEffect(() => {
    const finalSite = projects.filter((project) => project.site === site);

    if (finalSite.length < 1) {
      history.push('/');
      return;
    }
    setSiteData(finalSite[0]);
  }, [projects, site, history, siteData]);

  useEffect(() => {
    const currProjIndex = projects
      .map((e) => {
        return e.site;
      })
      .indexOf(site);

    const nextProj = projects[currProjIndex + 1];

    const prevProj = projects[currProjIndex - 1];

    setNextSite(nextProj);
    setPrevSite(prevProj);
  }, [projects, site]);

  return (
    <ProjectPage dark={dark} theme={siteData.theme}>
      <ProjectWrap>
        <ProjectHead dark={dark}>
          <Title theme={siteData.theme}>{siteData.title}</Title>
          <Subtitle theme={siteData.theme}>{siteData.clientInfo}</Subtitle>
        </ProjectHead>
        <GalleryWrap>
          <ProjectGallery gallery={siteData.gallery || []} />
        </GalleryWrap>
      </ProjectWrap>
      <ButtonsWrap>
        <ThemeBtn onClick={() => setDark(!dark)}>
          {dark ? <Light /> : <Dark />}
        </ThemeBtn>
        <Controls>
          {prevSite === undefined ? (
            <ControlBtn dark={true} disabled={true}>
              <Left />
            </ControlBtn>
          ) : (
            <ControlBtn
              dark={true}
              onClick={() => {
                history.push(`/${prevSite.site}`);
              }}
            >
              <Left />
            </ControlBtn>
          )}
          <ControlBtn all={true} onClick={() => history.push('/')}>
            <All />
          </ControlBtn>
          {nextSite === undefined ? (
            <ControlBtn dark={true} disabled={true}>
              <Right />
            </ControlBtn>
          ) : (
            <ControlBtn
              dark={true}
              onClick={() => {
                history.push(`/${nextSite.site}`);
              }}
            >
              <Right />
            </ControlBtn>
          )}
        </Controls>
      </ButtonsWrap>
    </ProjectPage>
  );
}
