import { useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import MailForm from '../mailForm';

import Portrait from '../../assets/Smail-Portrait.png';
import PortraitSmall from '../../assets/Smail-Portrait-Small.png';

import Illustration from '../../assets/Smail-About-Illustration.png';
import Design from '../../assets/Smail-About-Design.png';
import Marketing from '../../assets/Smail-About-Marketing.png';

import Socials from '../socials/';

const contactPortal = document.getElementById('contact-portal');

const SlideWrap = styled.div`
  width: 100%;
  position: fixed;
  overflow-y: scroll;
  top: 3.5rem;
  bottom: 0;
  background: #0e0e0e;
  transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-100%)')};
  transition: transform 0.4s;
  z-index: 5;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 768px) {
    top: 7rem;
  }
`;

const FadedBG = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: ${({ open }) => (open ? `block` : `none`)};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0e0e0e;
    opacity: 0.6;
    z-index: 1;
    &:hover {
      cursor: pointer;
    }
  }
`;

const Header = styled.span`
  display: block;
  font-family: dharma-gothic-e, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: calc(56px + (234 - 72) * ((100vw - 300px) / (4000 - 300)));
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  margin-top: 1rem;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const Subheader = styled.span`
  display: block;
  text-align: center;
  color: lightgray;
  font-family: pt-mono, monospace;
  font-weight: 400;
  font-style: normal;
  font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (3800 - 300)));
  margin: 0 auto;
  padding: 0 2rem;

  @media (min-width: 768px) {
    text-align: left;
    padding: 0;
    margin: 0;
    margin-left: 2px;
    max-width: 800px;
  }
`;

//intro section

const IntroSection = styled.section`
  background: #0e0e0e;
`;

const IntroContent = styled.div`
  margin-top: 4rem;
  margin-bottom: 2rem;
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    padding: 0 3rem;
    margin: 0;
  }

  @media (min-width: 1600px) {
    max-width: 1600px;
    margin: 0 auto;
  }
`;

const IntroText = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const PortraitImg = styled.img`
  display: block;
  width: 80%;
  margin: 1rem auto;
  /* max-width: 400px; */

  @media (min-width: 768px) {
    margin: 0;
    width: 50%;
    max-width: 1100px;
    /* max-height: 400px; */
    flex-grow: 1;
  }

  @media (min-width: 1650px) {
    /* margin-right: 8rem; */
  }
`;

const ContactBtn = styled.button`
  all: unset;
  position: relative;
  font-family: pt-mono, monospace;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  padding: 0.6rem 0;
  width: 60%;
  max-width: 300px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  border: 1px solid lightgray;
  background: transparent;

  font-family: var(--filter-font);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  span {
    position: relative;
    color: #fff;
    z-index: 3;
  }

  &::after {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    background: #fff;
    bottom: 0;
    right: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #fff;
    opacity: 0;
  }

  &:hover {
    cursor: pointer;
    span {
      color: #0e0e0e;
    }
    &::before {
      opacity: 1;
      z-index: 0;
    }
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

// about section
const AboutSection = styled.section`
  background: #1d1d1d;
`;

const AboutContent = styled.div`
  padding: 2rem 0;
  padding-bottom: 4rem;

  @media (min-width: 768px) {
    padding-top: 4rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 8rem;
  }

  @media (min-width: 1600px) {
    max-width: 1600px;
    margin: 0 auto;
  }
`;

const TopicsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin: 0 auto;
  margin-top: 2rem;
  gap: 4rem;
  justify-content: center;

  @media (min-width: 768px) {
    margin-top: 4rem;
  }
`;

const Topic = styled.div`
  display: grid;
  grid-template-rows: 250px 1fr;
`;

const TopicImg = styled.img`
  display: block;
  /* width: 80%;
  max-width: 200px; */
  height: inherit;
`;

const TopicImgWrap = styled.div`
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TopicContent = styled.div`
  text-align: center;
  margin: 0 auto;
  min-height: 214px;
  padding: 0 1rem;
  /* width: 85%; */

  @media (min-width: 768px) {
    padding: 0 3rem;
  }
`;

const TopicTitle = styled.span`
  display: block;
  font-size: calc(18px + (48 - 18) * ((100vw - 300px) / (4800 - 300)));
  font-weight: 600;
  font-style: normal;
  color: #fff;
  font-family: var(--filter-font);
  text-transform: uppercase;
  letter-spacing: 0.8px;

  @media (min-width: 768px) {
    margin-top: 1rem;
    text-align: center;
  }
`;

const TopicDetails = styled.p`
  display: block;
  color: lightgray;
  font-family: pt-mono, monospace;
  font-weight: 400;
  font-style: normal;
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (3800 - 300)));
  margin: 0 auto;
  margin-top: 1rem;
  line-height: calc(20px + (32 - 20) * ((100vw - 300px) / (3800 - 300)));
  word-wrap: break-word;
  width: 85%;

  @media (min-width: 768px) {
    width: 100%;
  }
`;

// contact section

const ContactSection = styled.section`
  position: relative;
  background: #fff;
`;

const ContactContent = styled.div`
  padding: 1rem 0;
  position: relative;

  @media (min-width: 768px) {
    padding: 4rem 3rem;
    padding-bottom: 2rem;
  }

  @media (min-width: 1600px) {
    max-width: 1600px;
    margin: 0 auto;
  }
`;

export default function ContactSlide({ open, setOpen }) {
  const contactSectionRef = useRef(null);

  const goToAbout = () => {
    contactSectionRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return ReactDOM.createPortal(
    <>
      <SlideWrap open={open}>
        <IntroSection>
          <IntroContent>
            <IntroText>
              <Header>
                <span style={{ color: '#fff' }}>Hello,</span> I'm Smail
              </Header>
              <Subheader>
                (pronounced 'smile') a graphic designer and illustrator based in
                the Des Moines metro area.
              </Subheader>
            </IntroText>

            <PortraitImg
              srcSet={`${PortraitSmall} 603w, ${Portrait} 750w`}
              src={Portrait}
              alt="Smail"
            />

            <ContactBtn onClick={goToAbout}>
              <span>Contact Me</span>
            </ContactBtn>
          </IntroContent>
        </IntroSection>
        <AboutSection>
          <AboutContent>
            <Header>
              Me, <span style={{ color: '#fff' }}>myself,</span> & I
            </Header>
            <Subheader>
              Since beginning my professional career I have acquired an arsenal
              of tools. Here are a few that I utilize the most.
            </Subheader>
            <TopicsContainer>
              <Topic>
                <TopicImgWrap>
                  <TopicImg src={Illustration} alt="Illustration" />
                </TopicImgWrap>
                <TopicContent>
                  <TopicTitle>01. Illustration</TopicTitle>
                  <TopicDetails>
                    When I'm not designing, I'm drawing. I enjoy creating pieces
                    that are bold and captivating; however, I have had the
                    opportunity to illustrate for many brands, giving me the
                    ability to work in a variety of styles.
                  </TopicDetails>
                </TopicContent>
              </Topic>
              <Topic>
                <TopicImgWrap>
                  <TopicImg src={Design} alt="Design" />
                </TopicImgWrap>
                <TopicContent>
                  <TopicTitle>02. Graphic Design</TopicTitle>
                  <TopicDetails>
                    In my experience as a designer, I have found I enjoy working
                    on projects that involve branding, typography, or layouts
                    with the goal of creating meaningful, everlasting designs
                    within corporate and small business contexts.
                  </TopicDetails>
                </TopicContent>
              </Topic>
              <Topic>
                <TopicImgWrap>
                  <TopicImg src={Marketing} alt="Marketing" />
                </TopicImgWrap>
                <TopicContent>
                  <TopicTitle>03. Marketing</TopicTitle>
                  <TopicDetails>
                    I like the challenge that comes with creating a successful
                    and strategic marketing campaign. By marketing for clients,
                    I hope to help them grow and reach a solid position in their
                    industry.
                  </TopicDetails>
                </TopicContent>
              </Topic>
            </TopicsContainer>
          </AboutContent>
        </AboutSection>
        <ContactSection ref={contactSectionRef}>
          <ContactContent>
            <Header style={{ color: '#1d1d1d' }}>
              <span style={{ color: '#1d1d1d' }}>Get</span> in touch
            </Header>
            <MailForm />
            <Socials />
          </ContactContent>
        </ContactSection>
      </SlideWrap>
      <FadedBG open={open} onClick={() => setOpen(false)} />
    </>,
    contactPortal
  );
}
