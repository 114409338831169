import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

const Box = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content {
    opacity: 0;
    padding: 5.4rem 1rem 2rem 1.5rem;
    position: absolute;
    z-index: 2;
    text-align: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin: auto;
    transition: opacity 0.5s;
    color: ${({ theme }) => theme || '#fff'};
    background: #1d1d1d;
    font-family: var(--sub-font);
    font-size: 14px;
    text-transform: uppercase;
  }

  .content-header {
    font-family: var(--header-font);
    font-size: 4rem;
    line-height: 3.4rem;

    &:after {
      content: '';
      display: block;
      width: 2.5rem;
      height: 3px;
      margin: 6px auto 0.5rem auto;
      background-color: ${({ theme }) => theme || '#fff'};
    }

    @media (min-width: 768px) {
      font-size: 3rem;
      line-height: 2.8rem;
    }
  }

  &:after {
    content: '';
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #0e0e0e;
    transition: opacity 0.5s;
  }

  @media (min-width: 768px) {
    justify-content: space-between;
    &:hover {
      &:after {
        opacity: 1;
      }

      .content {
        opacity: 1;
      }
    }
  }
`;

const StyledButton = styled.button`
  all: unset;
  position: relative;
  display: block;
  margin: 0 auto;
  margin-top: 1rem;
  width: 50%;
  border: 1px solid;
  border-color: ${({ theme }) => theme};
  color: ${({ theme }) => theme};
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  font-family: var(--filter-font);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 8px;
    height: 8px;
    background: ${({ theme }) => theme};
  }

  &:hover {
    cursor: pointer;
  }
`;

export default function ProjectThumbnail({
  title,
  img,
  theme,
  site,
  clientInfo,
}) {
  const history = useHistory();
  return (
    <Box
      theme={theme}
      onClick={() => {
        history.push(`/${site}`);
      }}
    >
      <LazyLoadImage
        effect="blur"
        height="inherit"
        width="inherit"
        alt={title}
        src={img}
      />
      {/* <img alt={title} src={img} /> */}
      <div className="content">
        <div>
          <h3 className="content-header">{title}</h3>
          <p style={{ textTransform: 'none', fontSize: '12px' }}>
            {clientInfo}
          </p>
        </div>
        <StyledButton theme={theme}>View Project</StyledButton>
      </div>
    </Box>
  );
}
