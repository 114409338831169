import styled from 'styled-components';

import { ReactComponent as Instagram } from '../../assets/instagram.svg';
import { ReactComponent as Dribbble } from '../../assets/dribbble-square.svg';
import { ReactComponent as Linkedin } from '../../assets/linkedin.svg';

const SocialsWrap = styled.div`
  display: flex;
  gap: 0.75rem;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    align-items: center;
  }

  svg {
    width: ${({ main }) => (main ? '16px' : '24px')};
    fill: ${({ main }) => (main ? '#fff' : '#1d1d1d')};
  }

  @media (min-width: 768px) {
    margin: unset;
    margin-top: ${({ main }) => (main ? '0' : '2rem')};
    justify-content: flex-end;
    padding-bottom: 0;
    gap: 0.75rem;
    svg {
      width: ${({ main }) => (main ? '16px' : '24px')};
    }
  }
`;

export default function Socials({ main }) {
  return (
    <SocialsWrap main={main}>
      <a href="https://dribbble.com/SmailBuljubasic">
        <Dribbble />
      </a>
      <a href="https://www.instagram.com/novoko.design/">
        <Instagram />
      </a>
      <a href="https://www.linkedin.com/in/smailbuljubasic/">
        <Linkedin />
      </a>
    </SocialsWrap>
  );
}
