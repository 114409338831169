import React, { useContext } from 'react';
import styled from 'styled-components';

import { AdminContext } from '../context/AdminContext';

import ProjectThumbnail from '../components/projectThumbnail';

const HomePage = styled.main`
  padding-top: 3.5rem;

  @media (min-width: 768px) {
    padding-top: 7rem;
  }
`;

const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  @media (min-width: 1800px) {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  @media (min-width: 2000px) {
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  }
`;

export default function Home() {
  const { projects } = useContext(AdminContext);

  return (
    <HomePage>
      <Gallery>
        {projects.map((project) => (
          <ProjectThumbnail
            title={project.title}
            img={project.img}
            desc={project.description}
            theme={project.theme}
            site={project.site}
            key={project.id}
            clientInfo={project.clientInfo}
          />
        ))}
      </Gallery>
    </HomePage>
  );
}
