import { createContext, useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';

import Deli from '../assets/cover-photos/Deli-Cover.jpg';
import BvsM from '../assets/cover-photos/BrandingVsMarketing-Cover.jpg';
import DLand from '../assets/cover-photos/Designland-Cover.jpg';
import Feather from '../assets/cover-photos/Featherlite-Cover.jpg';
import Holidays from '../assets/cover-photos/Holidays-Cover.jpg';
import Howerdon from '../assets/cover-photos/Howerdon-Cover.jpg';
import MondayMotivation from '../assets/cover-photos/MondayMotivation-Cover.jpg';
import MonsterMash from '../assets/cover-photos/MonsterMash-Cover.jpg';
import Navigate from '../assets/cover-photos/Navigate-Cover.jpg';
import SelfPromo from '../assets/cover-photos/SelfPromo-Cover.jpg';
import Apex from '../assets/cover-photos/WaukeeAPEX-Cover.jpg';
import BridgeStone from '../assets/cover-photos/Bridgestone-Cover.jpg';
import Sweden from '../assets/cover-photos/Sweden-Cover.jpg';
import FTlogo from '../assets/cover-photos/FT_Logo.jpg';

import LBB from '../assets/cover-photos/LBB-Cover.jpg';
import Katie from '../assets/cover-photos/Katie-Cover.jpg';
import Leona from '../assets/cover-photos/LeagueofLegends-Cover.jpg';
import Neverland from '../assets/cover-photos/PromisedNeverland-Cover.jpg';
import Hades from '../assets/cover-photos/Hades-Cover.jpg';
import Adam from '../assets/cover-photos/Adam-Cover.jpg';
import MrX from '../assets/cover-photos/MrX-Cover.jpg';

import { galleryImages } from '../assets/work/galleryImages';

export const AdminContext = createContext();

export function AdminProvider({ children }) {
  const [userInfo, setUserInfo] = useState({ username: '', password: '' });

  const storedToken = localStorage.getItem('adminToken');
  const [token, setToken] = useState(storedToken || '');

  const [filter, setFilter] = useState('');

  const [projects] = useState([
    {
      id: 0,
      title: 'Illustrations',
      site: 'firetrucker-brewery',
      clientInfo: 'Firetrucker Brewery',
      img: FTlogo,
      gallery: galleryImages.ft,
      theme: '#fff',
    },
    {
      id: 1,
      title: 'Rebranding',
      site: 'manhattan-deli',
      clientInfo: `Hansen's Manhattan Deli`,
      img: Deli,
      gallery: galleryImages.deli,
      theme: '#fff',
    },
    {
      id: 2,
      title: 'Branding vs Marketing',
      site: 'branding-vs-marketing',
      clientInfo: `Farmboy Inc.`,
      img: BvsM,
      gallery: galleryImages.bvsm,
      theme: '#fff',
    },
    {
      id: 3,
      title: '62nd Design Exhibition',
      site: 'designland',
      clientInfo: `Art Director's Association of Iowa`,
      img: DLand,
      gallery: galleryImages.designland,
      theme: '#fff',
    },
    {
      id: 553,
      title: 'Zagreus',
      site: 'zagreus',
      clientInfo: 'Fan Art',
      img: Hades,
      gallery: galleryImages.illustrations.hades,
      theme: '#fff',
    },
    {
      id: 5,
      title: 'Holiday Social Posts',
      site: 'holidays',
      clientInfo: 'Farmboy Inc.',
      img: Holidays,
      gallery: galleryImages.holidays,
      theme: '#fff',
    },
    {
      id: 395,
      title: 'Katie',
      site: 'katie',
      clientInfo: 'Personal Work',
      img: Katie,
      gallery: galleryImages.illustrations.katie,
      theme: '#fff',
    },
    {
      id: 34,
      title: 'Branding',
      site: 'howerdon',
      clientInfo: 'Howerdon Creek Farms',
      img: Howerdon,
      gallery: galleryImages.howerdon,
      theme: '#fff',
    },
    {
      id: 3532,
      title: 'Little Blue Booger',
      site: 'lbb',
      clientInfo: 'Commission',
      img: LBB,
      gallery: galleryImages.illustrations.lbb,
      theme: '#fff',
    },
    {
      id: 11,
      title: 'Annual Report',
      site: 'waukee-apex',
      clientInfo: 'Waukee APEX',
      img: Apex,
      gallery: galleryImages.apex,
      theme: '#fff',
    },
    {
      id: 6,
      title: 'Monday Motivation',
      site: 'monday-motivation',
      clientInfo: 'Farmboy Inc.',
      img: MondayMotivation,
      gallery: galleryImages.monday,
      theme: '#fff',
    },
    {
      id: 3539,
      title: 'International Year Poster',
      site: 'year-poster',
      clientInfo: `DMACC`,
      img: Sweden,
      gallery: galleryImages.sweden,
      theme: '#fff',
    },
    {
      id: 7,
      title: 'Monster Mash',
      site: 'monster-mash',
      clientInfo: 'Farmboy Inc.',
      img: MonsterMash,
      gallery: galleryImages.monster,
      theme: '#fff',
    },
    {
      id: 1490,
      title: 'Anniversary Portrait',
      site: 'anniversary-portrait',
      clientInfo: `Commission`,
      img: Adam,
      gallery: galleryImages.illustrations.adam,
      theme: '#fff',
    },
    {
      id: 529,
      title: 'Magazine Cover',
      site: 'magazine-cover',
      clientInfo: 'Bridgestone',
      img: BridgeStone,
      gallery: galleryImages.bridgestone,
      theme: '#fff',
    },
    {
      id: 8,
      title: 'Mr.X',
      site: 'mrx',
      clientInfo: 'Fan Art',
      img: MrX,
      gallery: galleryImages.illustrations.mrx,
      theme: '#fff',
    },
    {
      id: 9,
      title: 'Wall Murals',
      site: 'navigate',
      clientInfo: 'Navigate Wellbeing Solutions',
      img: Navigate,
      gallery: galleryImages.navigate,
      theme: '#fff',
    },
    {
      id: 95092,
      title: 'Promised Neverland',
      site: 'promised-neverland',
      clientInfo: 'Fan Art',
      img: Neverland,
      gallery: galleryImages.illustrations.neverland,
      theme: '#fff',
    },
    {
      id: 4,
      title: 'Trailer Illustrations',
      site: 'featherlite',
      clientInfo: `Featherlite Trailers`,
      img: Feather,
      gallery: galleryImages.flite,
      theme: '#fff',
    },
    {
      id: 9252,
      title: 'Leona',
      site: 'leona',
      clientInfo: 'Fan Art',
      img: Leona,
      gallery: galleryImages.illustrations.league,
      theme: '#fff',
    },
    {
      id: 10,
      title: 'Farmboy Self Promo',
      site: 'self-promo',
      clientInfo: 'Farmboy Inc.',
      img: SelfPromo,
      gallery: galleryImages.spromo,
      theme: '#fff',
    },
  ]);

  const handleChange = (e) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    localStorage.setItem('adminToken', token);
  }, [token]);

  return (
    <AdminContext.Provider
      value={{
        test: 'hello',
        userInfo,
        handleChange,
        setToken,
        projects,
        filter,
        setFilter,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
}
