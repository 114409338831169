import styled from 'styled-components';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

const GalleryContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 1rem;
  margin-top: 1rem;
  min-height: 40vh;

  @media (min-width: 768px) {
    margin-top: 6rem;
    margin-bottom: 6rem;
    min-height: 80vh;
  }
`;

const MainImg = styled(LazyLoadImage)`
  width: 100%;
`;

export default function ProjectGallery({ gallery }) {
  return (
    <GalleryContainer>
      {gallery.map((img) => (
        <MainImg
          effect="opacity"
          key={img}
          alt="Main gallery image"
          src={img}
        />
      ))}
    </GalleryContainer>
  );
}
