//work
import designLand1 from './designland/Designland-1.png';
import designLand2 from './designland/Designland-2.png';
import designLand3 from './designland/Designland-3.png';
import designLand4 from './designland/Designland-4.png';
import designLand5 from './designland/Designland-5.png';
import designLand6 from './designland/Designland-6.png';
import designLand7 from './designland/Designland-7.jpg';
import designLand8 from './designland/Designland-8.png';
import designLand9 from './designland/Designland-9.png';
import designLand10 from './designland/Designland-10.png';

import bvsm1 from './bvsm/BVM-1.jpg';
import bvsm2 from './bvsm/BVM-2.jpg';
import bvsm3 from './bvsm/BVM-3.jpg';
import bvsm4 from './bvsm/BVM-4.jpg';

import apex1 from './apex/Apex-1.jpg';
import apex2 from './apex/Apex-2.jpg';
import apex3 from './apex/Apex-3.jpg';
import apex4 from './apex/Apex-4.jpg';
import apex5 from './apex/Apex-5.jpg';

import bridgestone1 from './bridgestone/Bridgestone.jpg';

import deli1 from './deli/Deli-1.png';
import deli2 from './deli/Deli-2.png';
import deli3 from './deli/Deli-3.png';
import deli4 from './deli/Deli-4.png';
import deli5 from './deli/Deli-5.png';
import deli6 from './deli/Deli-6.png';
import deli7 from './deli/Deli-7.png';
import deli8 from './deli/Deli-8.png';

import flite1 from './featherlite/Trailer-1.png';
import flite2 from './featherlite/Trailer-2.png';
import flite3 from './featherlite/Trailer-3.png';
import flite4 from './featherlite/Trailer-4.png';
import flite5 from './featherlite/Trailer-5.png';
import flite6 from './featherlite/Trailer-6.png';
import flite7 from './featherlite/Trailer-7.png';

import holidays1 from './holidays/Holiday-1.png';
import holidays2 from './holidays/Holiday-2.jpg';
import holidays3 from './holidays/Holiday-3.jpg';
import holidays4 from './holidays/Holiday-4.jpg';

import howerdon1 from './howerdon/Howerdon.png';

import monday1 from './monday/MM-1.png';
import monday2 from './monday/MM-2.jpg';
import monday3 from './monday/MM-3.jpg';
import monday4 from './monday/MM-4.jpg';

import monster1 from './monstermash/MonsterMash-1.jpg';
import monster2 from './monstermash/MonsterMash-2.jpg';
import monster3 from './monstermash/MonsterMash-3.jpg';
import monster4 from './monstermash/MonsterMash-4.jpg';

import navigate1 from './navigate/Navigate-1.jpg';
import navigate2 from './navigate/Navigate-2.jpg';
import navigate3 from './navigate/Navigate-3.jpg';
import navigate4 from './navigate/Navigate-4.jpg';
import navigate5 from './navigate/Navigate-5.jpg';
import navigate6 from './navigate/Navigate-6.jpg';
import navigate7 from './navigate/Navigate-7.jpg';

import spromo1 from './selfpromo/SelfPromo.png';

import sweden1 from './sweden/Sweden.jpg';

import ft1 from './ft/FT_TropicalBurn.png';
import ft2 from './ft/FT_BrigadeAle.png';
import ft3 from './ft/FT_CopperCat.png';
import ft4 from './ft/FT_TradingCards.png';
import ft5 from './ft/FT_Oktoberfest.png';
import ft6 from './ft/FT_Halloween.png';
import ft7 from './ft/FT_Christmas.png';

//illustrations
import adam1 from './illustrations/adam/Adam.jpg';
import adam2 from './illustrations/adam/Courtney.jpg';

import hades1 from './illustrations/hades/Zagreus.jpg';

import katie1 from './illustrations/katie/Katie-1.jpg';
import katie2 from './illustrations/katie/Katie-2.jpg';
import katie3 from './illustrations/katie/Katie-3.jpg';

import lbb1 from './illustrations/lbb/LBB-1.jpg';
import lbb2 from './illustrations/lbb/LBB-2.jpg';
import lbb3 from './illustrations/lbb/LBB-3.jpg';
import lbb4 from './illustrations/lbb/LBB-4.jpg';
import lbb5 from './illustrations/lbb/LBB-5.jpg';
import lbb6 from './illustrations/lbb/LBB-6.jpg';
import lbb7 from './illustrations/lbb/LBB-7.jpg';
import lbb8 from './illustrations/lbb/LBB-8.jpg';
import lbb9 from './illustrations/lbb/LBB-9.jpg';

import league1 from './illustrations/lol/Leona.jpg';

import neverland1 from './illustrations/neverland/Ray.jpg';

import mrx1 from './illustrations/mrx/X.png';

export const galleryImages = {
  apex: [apex1, apex2, apex3, apex4, apex5],
  bridgestone: [bridgestone1],
  bvsm: [bvsm1, bvsm2, bvsm3, bvsm4],
  deli: [deli1, deli2, deli3, deli4, deli5, deli6, deli7, deli8],
  designland: [
    designLand1,
    designLand2,
    designLand3,
    designLand4,
    designLand5,
    designLand6,
    designLand7,
    designLand8,
    designLand9,
    designLand10,
  ],
  flite: [flite1, flite2, flite3, flite4, flite5, flite6, flite7],
  ft: [ft1, ft2, ft3, ft4, ft5, ft6, ft7],
  holidays: [holidays1, holidays2, holidays3, holidays4],
  howerdon: [howerdon1],
  monday: [monday1, monday2, monday3, monday4],
  monster: [monster1, monster2, monster3, monster4],
  navigate: [
    navigate1,
    navigate2,
    navigate3,
    navigate4,
    navigate5,
    navigate6,
    navigate7,
  ],
  spromo: [spromo1],
  sweden: [sweden1],
  illustrations: {
    katie: [katie1, katie2, katie3],
    hades: [hades1],
    lbb: [lbb1, lbb2, lbb3, lbb4, lbb5, lbb6, lbb7, lbb8, lbb9],
    league: [league1],
    neverland: [neverland1],
    adam: [adam1, adam2],
    mrx: [mrx1],
  },
};
