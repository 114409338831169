import styled from 'styled-components';

import Socials from '../socials/';

const TextWrap = styled.div`
  height: 4rem;
  opacity: 0.6;
  color: #fff;
  font-size: 10px;
`;

const Center = styled.div`
  display: flex;
  height: inherit;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 1rem;
  @media (min-width: 768px) {
    padding: 0 3rem;
    flex-direction: row;
  }
`;

export default function Footer() {
  return (
    <TextWrap>
      <Center>
        <div>
          © Smail Buljubasic | Coded by{' '}
          <a
            href="https://almir.dev"
            style={{ textDecoration: '', color: 'inherit' }}
            target="_blank"
            rel="noreferrer"
          >
            Almir
          </a>
        </div>
        <Socials main={true} />
      </Center>
    </TextWrap>
  );
}
